import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="h-8 bg-white flex justify-center items-center">
        <p>Currently on SOLANA devnet</p>
      </div>
    </footer>
  );
}